import React from "react";
import Navbar from "../navbar/Navbar";
import { Container, Breadcrumb } from "react-bootstrap";
import "./style.css";
import { homePage, } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import AgreementContent from "./AgreementContent";

const AgreementPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="agreementMainDiv">
        <Container fluid>
          <Breadcrumb>
            <a
              className="disabled text-light opacity-50 text-decoration-none cursor-pointer agreement-side "
              onClick={() => {
                homePage(navigate);
              }}
            >
              Home
            </a>
            {location.state === "T&C" ? (
              <a className="text-light text-decoration-none agreement-side">
                &nbsp; &gt; Terms &amp;<span> Conditions</span>
              </a>
            ) : (
              <a className="text-light text-decoration-none agreement-side">
                {" "}
                &nbsp; &gt; Privacy Policy
              </a>
            )}
          </Breadcrumb>
          <AgreementContent from={location.state}/>
   
        </Container>
      </div>
      <div className="">
        <Footer />
      </div>
    </>
  );
};

export default AgreementPage;
