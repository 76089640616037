import { Container, Col, Row } from "react-bootstrap";
import "./style.css";
import { allimages } from "../../common/helper/AllImg/Allimages";
import { Fade } from "react-reveal";
import Flash from "react-reveal/Flash";
import Slider from "react-slick";

function RoleBasedManger() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    prevArrow: <allimages.processicon2 />,
    nextArrow: <allimages.processicon1 />,
    slidesToShow: 3, // Default number of slides to show
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Adjust settings for screens larger than 1024px (laptops and large screens)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700, // Adjust settings for screens larger than 768px and up to 1024px (tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Adjust settings for screens up to 768px (mobile devices)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // Add more breakpoints as needed
    ],
  };

  const cardContent = [
    {
      id: 1,
      direction: `left`,
      image: allimages.courselGroupImg,
      heading: "League Admin",
      content:
        "Assign the management of your squad, setup your public fantasy page.",
    },
    {
      id: 2,
      direction: "bottom",
      image: allimages.coureselpadimg,
      heading: "Head Coach",
      content: "Set Rosters, manage trades, drop  players & manage the team.",
    },
    {
      id: 3,
      direction: "right",
      image: allimages.courselchat,
      heading: "Assistant Coach",
      content: "Act as a crucial player to assist the head coach & his duties.",
    },
    {
      id: 4,
      direction: `left`,
      image: allimages.handShakesvg,
      heading: "Head Negotiator",
      content:
        "Be the point of contact, negotiating across  teams to get the best trades for your squad.",
    },
    {
      id: 5,
      direction: "bottom",
      image: allimages.helmet,
      heading: "Waiver Wizard",
      content:
        "Be a wavier expert, know which sleepers should be on your team's radar and act on it!.",
    },
    {
      id: 6,
      direction: "right",
      image: allimages.scrumTeam,
      heading: "Squad Member",
      content:
        "Vote on crucial decisions like waiver ads, trades, starting lineups to influence your management squad.",
    },
  ];

  const leftSideContent = [
    {
      id: 1,
      heading: "Who Should Start?",
      content:
        "Which players you believe should be in your starting lineup" +
        " every week to score the most vs. the competing squad.",
    },
    {
      id: 2,
      heading: "Trade Proposals ",
      content: "Whether a trade should be accepted or denied.",
    },
  ];

  const rightSideContent = [
    {
      id: 1,
      heading: "Waiver Additions",
      content:
        "Which free agents should added to your team roster to increase your chances of winning.",
    },
  ];

  return (
    <>
      <Container className="mainPage floating ">
        <center>
          <h2 className="mb-3 coursel-header animate__animated animate__flipInX ">
            Group-Based Fantasy Leagues
          </h2>
        </center>
        {/* Role Based Management Section */}
        <div className="d-flex justify-between items-stretch flex flex-wrap gap-5 coursel-header-content">
          <div className="flex flex-col ">
            <Flash cascade>
              <div className="text-white">
                <center></center>
              </div>
            </Flash>
            <div className="coursel-content  mt-3 w-75 mb-4">
              <p> Everyone on a team or &apos;squad&apos; has a role!</p>
              <br />
              <p>
                Whether a management position (head coach, assistant coach,
                etc.) or are a non management role (squad members),
                participation and <span className="sqdName-mini">insights</span>{" "}
                are crucial.
              </p>
            </div>
          </div>
        </div>

        {/* Carousel Section */}
        <Slider {...settings} className="processsliding ">
          {cardContent.map((cardItem) => (
            <div key={cardItem.id}>
              <div className="coursel-card-main items-stretch d-flex max-w-[400px] flex-col px-8 py-11 rounded-3xl">
                <div className="coursel-card-header justify-between items-stretch d-flex gap-4">
                  <cardItem.image />
                  <div className="text-white text-2xl font-bold self-center grow shrink basis-auto my-auto">
                    <h5>{cardItem.heading}</h5>
                  </div>
                </div>
                <div className="bg-white bg-opacity-40 flex shrink-0 h-px flex-col mt-10" />
                <div className="text-white text-opacity-60 text-xl leading-8 mt-10 mb-5">
                  <p>{cardItem.content}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>

      {/* 3-image-3 section */}
      <section className="mt-5">
        <Container>
          <center>
            <h2 className="mb-3 coursel-header animate__animated animate__flipInX ">
              Insights from Collaboration
            </h2>
          </center>
          {/* Role Based Management Section */}
          <div
            className="d-flex justify-between items-stretch flex flex-wrap gap-5 coursel-header-content"
            style={{ color: "#a2a3a6" }}
          >
            <div className="flex flex-col ">
              <div className=" mt-3 w-100 mb-4" style={{ fontSize: "20px" }}>
                <p>
                  {" "}
                  All squad members have the opportunity impact the outcome of
                  their squads roster...
                </p>
                <br />
                <p>
                  Add your insights within the app to benefit your squad by
                  <span className="sqdName-mini">voting on</span> :
                </p>
              </div>
            </div>
          </div>
          <Row>
            <Col
              xs={12}
              md={4}
              className="d-flex flex-column roleBasesection-left"
            >
              <Fade left>
                {/* Left Side Content */}
                {leftSideContent.map((content) => {
                  return (
                    <>
                      <img src={allimages.dot} alt="dot" />
                      <div key={content.id} className="mb-4">
                        <h5 className="main-heading">{content.heading}</h5>
                        <p style={{ color: "#a2a3a6" }}>{content.content}</p>
                      </div>
                    </>
                  );
                })}
              </Fade>
            </Col>

            <Col
              xs={12}
              md={4}
              className="d-flex  justify-content-center   vertical-align-middle;"
            >
              <img
                src={allimages.centerImgrole}
                alt="rolebasemanagement"
                className="img-fluid"
              />
            </Col>
            <Col
              xs={12}
              md={4}
              className="d-flex flex-column  justify-content-center "
            >
              <Fade right>
                <>
                  <img src={allimages.dot} alt="dot" />
                  <div key={rightSideContent[0].id} className="mb-4">
                    <h5 className="main-heading">
                      {rightSideContent[0].heading}
                    </h5>
                    <p style={{ color: "#a2a3a6" }}>
                      {rightSideContent[0].content}
                    </p>
                  </div>
                </>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default RoleBasedManger;
