import React from "react";
import { useNavigate } from "react-router-dom";
import { openmodel } from "./utils";
import Table from "react-bootstrap/Table";
import PropTypes from "prop-types";
import "./style.css";

// eslint-disable-next-line react/prop-types
const AgreementContent = ({ from }) => {
  const navigate = useNavigate();
  return from == "T&C" ? (
    <div className="main-content">
      <div>
        <div className="d-flex flex-column justify-content-center card-header">
          <h3>
            TERMS OF &nbsp; <span>SERVICE</span>
          </h3>
          <p>Last Updated: November 10, 2023 </p>
        </div>
      </div>

      <div className="card-body">
        <h4>{/* Interpretation and Definitions */}</h4>
        <p>
          These Terms of Service apply to the Huddle Fantasy Sports website and
          mobile application, as well as the fantasy league platform featured
          thereon (the “Platform”), including both private leagues and public
          leagues (collectively, the “Services”). The Terms of Service set forth
          the agreement between you and Huddle Fantasy Sports (each, a “Party”
          and collectively, the “Parties”) with respect to each Party’s rights
          and responsibilities regarding the Services. We ask that you review
          these Terms of Service (alternatively, the “Agreement”) carefully, as
          your use of the Services constitutes your agreement to be bound by
          these Terms of Service.
        </p>
        <p>
          By using this site, you agree to comply with and be bound by the
          following terms and conditions of use. Please review these terms and
          conditions carefully. If you do not agree to these terms and
          conditions, you should not use this site.
        </p>
        <br />
        <h4>Acceptance of Terms of Service</h4>
        <p>
          By using the Services, you understand and agree that these Terms of
          Service are a legally binding agreement between you and Huddle Fantasy
          Sports that is the legal equivalent of a written contract signed by
          both parties.
        </p>
        <p>
          Your agreement to these Terms of Service is confirmed by clicking on
          the “I Agree” or “Accept” or other similarly worded entry field or
          “button” on the Services as well as by your continued use of the
          Services, whether you have created an account or whether you simply
          browse, use, or access the Services. You agree that such affirmations
          are legally binding statements indicating agreement to be bound by
          these Terms of Service that are enforceable as the legal equivalent of
          your handwritten signature. You further consent to electronic
          communication with Huddle Fantasy Sports and agree that transmitting
          electronic communications to Huddle Fantasy Sports will be considered
          “in writing” for purposes of these Terms of Service.{" "}
        </p>
        <p>
          By using the Services, you agree that you have received, reviewed, and
          understand these Terms of Service and that you agree to be bound by
          these Terms of Service after consideration of the information and
          restrictions on your use of the Services contained herein.{" "}
        </p>
        <p>
          If you do not agree with these Terms of Service, we ask that you
          please discontinue all further use of the Services and delete your
          account from the Services.
        </p>
        <br />
        <h4>
          {/* Office ipsum you must be muted. */}
          Huddle Fantasy Sports’ License to You
        </h4>
        <p>
          <b>Grant</b>&nbsp; Huddle Fantasy Sports hereby grants to you and you
          accept a non-exclusive and non-transferable personal license to access
          and use the Huddle Fantasy Sports web-based software (the “User
          License”) in the manner described in these Terms of Service and
          subject to payment of applicable license or subscription fees. This
          User License is expressly conditioned on your continued compliance
          with these Terms of Service. All rights not expressly granted in these
          Terms of Service are reserved to Huddle Fantasy Sports. Nothing in
          these Terms of Service obligates Huddle Fantasy Sports to continue
          providing access to the Services beyond the date when Huddle Fantasy
          Sports ceases to provide access to the Services to users generally.
          There are no third-party beneficiaries of these Terms of Service.{" "}
        </p>
        <p>
          <b>Prohibition on Transfer</b>&nbsp; Any attempts to transfer the User
          License to another party without the express written consent of Huddle
          Fantasy Sports will be invalid and void, including without limitation,
          any attempt to rent, lease, share, sell, transfer, redistribute, or
          sublicense the User License to any other third party.
        </p>
        <h4>Restrictions on the User License</h4>
        <p>
          You agree to be bound by and comply with the following restrictions on
          the User License.
        </p>
        <p>
          <ol>
            <li>
              You agree to use the Services only in a manner and for the purpose
              for which the Services were designed, such as actively
              participating in a public or private fantasy league offered by
              Huddle Fantasy Sports, or viewing the progress of a public league.
              All other uses of the Services must be expressly approved by
              Huddle Fantasy Sports in writing. Further, you will not use the
              Services for any purpose that is unlawful, tortious, abusive,
              intrusive on another’s privacy, harassing, libelous, defamatory,
              embarrassing, obscene, threatening, hateful, or otherwise
              objectionable to Huddle Fantasy Sports or harmful to the goodwill
              of Huddle Fantasy Sports’ business.
            </li>
            <li>
              You agree not to use the Services in connection with any
              commercial purpose.
            </li>
            <li>
              You will not use,upload, post, reproduce, or distribute any
              information, software, or other material from the Services that is
              protected by copyright or any other intellectual property right
              (as well as rights of publicity and privacy) without first
              obtaining the permission of Huddle Fantasy Sports or the
              appropriate owner in writing.
            </li>
            <li>
              You will not collect or store personal data, or attempt to do the
              same, of other users of the Services.
            </li>
            <li>
              You will not take any action or upload any content that may
              interrupt, limit, or interfere with the functionality of the
              Services, or of any service, network, or hardware that supports
              the Services.
            </li>
            <li>
              You will use the Services in a manner consistent with applicable
              laws and regulations and in accordance with these Terms of
              Service, as they may be amended by Huddle Fantasy Sports from time
              to time.
            </li>
            <li>
              You will not decompile, reverse-engineer, disassemble, attempt to
              derive the source code of, modify, create unauthorized copies, or
              create derivative works of the Services, any updates, or any part
              thereof (except as and only to the extent that any foregoing
              restriction is prohibited by applicable law). You will not access
              the Services for any competitive purpose. You also agree that you
              will not assist third parties in such prohibited acts.
            </li>
            <li>
              You will take reasonable measures to maintain the security of your
              account, including, but not limited to, protecting your account
              credentials, and using your account only on devices that you own
              or control.
            </li>
            <li>
              You will not provide access to, or allow, unauthorized third
              parties to use your account. Authorized third parties may use your
              account with your express permission. However, you understand and
              agree that you are solely responsible for any actions taken and
              fees due that originate from your account, including actions by
              you, your authorized third party users, and unauthorized third
              parties. You agree to defend and hold Huddle Fantasy Sports
              harmless from any disputes regarding actions on the Services that
              originate from your account.
            </li>
            <li>
              You will not use, or attempt to use, automated programs or
              software to access the Services. Automated access to the Services
              is strictly prohibited.
            </li>
            <li>
              You will not use or attempt to use the Services in any way that
              falsely suggests or implies endorsement by a third party.
            </li>
            <li>
              If your participation on the Services is in any way sponsored by a
              third party, we require that you disclose the fact of your
              sponsorship to Huddle Fantasy Sports. Huddle Fantasy Sports is
              entitled to a portion of your sponsorship revenue as determined on
              a case-by-case basis. <br />
            </li>
          </ol>
          Violation of the restrictions on the User License above, or of any
          other provision of these Terms of Service, will be considered a
          default of these Terms of Service and Huddle Fantasy Sports reserves
          the right, in its sole discretion, to take appropriate corrective
          action, including but not limited to, disabling or deactivating,
          either temporarily or permanently, any accounts that fail to comply
          with these Terms of Service.
        </p>
        <br />
        <h4>
          {/* Links to Other Websites */}
          User Content
        </h4>
        <p>
          Through your use of the Services, you may choose to submit, upload, or
          make otherwise available texts, posts, comments, images, messages,
          chats, video, audio, or other content (collectively, “User Generated
          Content”). Huddle Fantasy Sports does not own your User Generated
          Content, but you grant Huddle Fantasy Sports a non-exclusive,
          sublicensable, irrevocable, worldwide, transferable, and royalty-free
          license to host, use, distribute, modify, run, copy, publicly perform,
          publicly display, publish, exhibit, translate, and create derivative
          works of your content.
        </p>
        <p>
          Further, you understand that posting User Content is solely at your
          own risk and that you are solely responsible and liable for the
          consequences of doing so. You agree that Huddle Fantasy Sports acts
          only as a passive conduit for your online distribution and publication
          of User Content. Huddle Fantasy Sports, however, reserves the right to
          remove any User Content from the Services at its sole discretion. By
          transmitting and submitting any User Content while using the Services,
          you agree as follows:{" "}
          <ul>
            <li>
              You are solely responsible for your account and the activity that
              occurs while signed in to or while using your account;{" "}
            </li>

            <li>
              You will not post information that is malicious, libelous, false,
              or inaccurate;
            </li>
            <li>
              You will not post any information that is abusive, threatening,
              obscene, defamatory; libelous, or racially, sexually, religiously,
              or otherwise objectionable and offensive.
            </li>
          </ul>
        </p>
        <h4>
          {/* Links to Other Websites */}
          Game Data
        </h4>
        <p>
          As part of the gameplay associated with the Platform, certain data
          will be generated and associated to your account if you participate in
          a public or private league on the Platform. Namely, this data may
          include statistics about how you vote on team decisions (“Voting
          Insights”) as well as game results, game statistics, and player
          statistics for players on your team. You agree to permit Huddle
          Fantasy Sports to use this data, as it is associated with your team,
          in connection with its marketing and advertising efforts.
        </p>
        <p>
          You also agree that, if you participate in a public league on the
          platform (as opposed to a private league), that Huddle Fantasy Sports
          may make certain game data available for public viewing (“Public Game
          Data”). Such Public Game Data may include, but is not limited to,
          Voting Insights, team names, team profile photos, individual users
          names, individual user profile photos, game results, game statistics,
          and player statistics.
        </p>
        <h4> Privacy Policy </h4>
        <p>
          Your use of the Services and your use of User Content is also governed
          by our Privacy Policy, &nbsp;
          <a
            href="/agreement"
            onClick={() => {
              openmodel(navigate, "privacy");
            }}
          >
            here.
          </a>{" "}
          &nbsp; The Privacy Policy is incorporated herein into these Terms of
          Service in its entirety, as the same may be modified from time to
          time.
        </p>
        <h4> Fees </h4>
        <p>
          In exchange for participation in a public league, you will pay to
          Huddle Fantasy Sports the fee indicated on the Services. Huddle
          Fantasy Sports reserves the right to charge a recurring fee for access
          to the Services for a set time period. The amount of fees due and the
          time period for recurring fees are determined by Huddle Fantasy Sports
          in their sole discretion according to market factors and may be
          changed from time to time. Huddle Fantasy Sports reserves the right to
          charge late charges in an amount determined in the sole discretion of
          Huddle Fantasy Sports on any fees that have not been paid within 30
          days of the original fee due date.
        </p>
        <p>
          When we update our fee period and the amount of fees due, we will use
          reasonable efforts to provide you with advanced notice of the same so
          that you may decide whether to continue using the Services. However,
          the above will not be construed as creating an obligation for Huddle
          Fantasy Sports to provide you with a set amount of, or any, advance
          notice regarding a change in fees due for use of Huddle Fantasy Sports
        </p>
        <p>
          If you fail to pay the required fees, access to the Services will be
          denied or terminated. Access to the Services may be reinstated by
          making payment in full of any current and past fees due on your
          account.
        </p>
        <p>
          {" "}
          Your carrier’s data rates may apply to your use of the Services.
          Huddle Fantasy Sports has no responsibility or obligation to pay your
          carrier service charges or data charges related to your use of the
          Services.
        </p>
        <h4>Refund Policy</h4>
        <p>
          There are no refunds or cancellations for purchases made through the
          Services. However, you will still have access to the paid-only areas
          of the Services until the end of the period you have paid for.
        </p>
        <h4> Term, Modifications, and Termination </h4>
        <p>
          These Terms of Service will be effective as of the date of your first
          use of the Services or the date of your agreement to these Terms of
          Service, whichever is earlier, and continue in perpetuity for the
          duration of your use of the Services or until discontinuation of the
          Services by Huddle Fantasy Sports
        </p>
        <p>
          These Terms of Service may be updated, edited, modified, changed, or
          revised at any time and from time to time in the sole discretion of
          Huddle Fantasy Sports Changes to these Terms of Service become
          effective when an updated version of the Terms of Service are posted
          to the Services. As such, we ask that you check these Terms of Service
          periodically for updates. We will use reasonable business efforts to
          provide you with notice of an update or change to these Terms of
          Service, but we are not obligated to provide notice to you. Each use
          of the Services constitutes a separate affirmation or acceptance of
          the Terms of Service in effect at that time, including any changes to
          these Terms of Service that went into effect since your prior use(s).
          The most recent version of these Terms of Service supersedes all
          previous versions.
        </p>
        <p>
          Any new features that are added to the Services, including the release
          of new features, courses, products, resources, or applications, will
          be subject to these Terms of Service. Your continued use of the
          Services after any such change(s) will constitute your full consent to
          these Terms of Service with respect to such changes and new features.
        </p>
        <p>
          {" "}
          You may terminate this Agreement at any time by deleting your account.
          Huddle Fantasy Sports may terminate this Agreement at any time in
          their sole discretion. Sections 4, 6, 11, 13, 14, and 15 of these
          Terms of Service will survive termination by either Party.
        </p>
        <h4> Security of Data Transmission and Storage </h4>
        <p>
          When you upload or create User Content on the Services, we may
          transmit that data and information to remote servers to provide our
          full range of services. You expressly consent to storage of User
          Content on our servers or third party servers and transmission of User
          content between the Services and the servers. Not all communications
          or transmissions of data are encrypted. Submitting User Content
          through the Services is solely at your own risk and you understand and
          agree that we may store User Content for future access and use in
          accordance with these Terms of Service and our Privacy Policy. Please
          see our Privacy Policy for more details regarding how we store and
          protect your information.
        </p>
        <h4>Third Party Links </h4>
        <p>
          The Services, including User Content displayed on the Services, may
          contain links to other third party websites or applications. Huddle
          Fantasy Sports does not control such other parties and their websites
          or mobile applications, and Huddle Fantasy Sports and its affiliates
          and agents make no representations whatsoever concerning the content,
          accuracy, or security of those sites. The fact that Huddle Fantasy
          Sports has provided a link to a site is not an endorsement,
          authorization, sponsorship, or affiliation with respect to such site,
          its affiliates or its providers. Your use of third party websites and
          mobile applications is solely at your own risk and you agree to defend
          and hold Huddle Fantasy Sports harmless from any loss or damage caused
          to you or your business through third party websites or mobile
          applications.
        </p>
        <h4> Ownership of Intellectual Property </h4>
        <p>
          The Services are owned by Huddle Fantasy Sports or its affiliates.
          Certain aspects of the Services, including but not limited to the
          Huddle Fantasy Sports name, the source and object code, software, user
          interface, images and other like material are protected by applicable
          United States intellectual property laws and corresponding
          international treaty provisions. All content, software, trademarks,
          services marks, trade names, logos, and icons are proprietary property
          of Huddle Fantasy Sports or its affiliates or agents, or are used by
          Huddle Fantasy Sports with permission from third party owners. Nothing
          contained in the Services, these Terms of Service, or the Privacy
          Policy should be construed as granting, by implication, estoppel, or
          otherwise, any license or right to use any intellectual property
          displayed or contained in the Services without the written permission
          of Huddle Fantasy Sports or such third party that may own the relevant
          intellectual property. Your use of any intellectual property displayed
          or contained in the Services, or any other content in the Services
          except as provided herein, is strictly prohibited.
        </p>
        <h4> Intellectual Property Disputes </h4>
        <p>
          If you believe your work has been copied in a way that constitutes
          copyright infringement, or if you believe your intellectual property
          rights have otherwise been violated, please provide us with a notice
          containing all of the following inf ormation:
          <ol>
            <li>
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright or other intellectual
              property interest;
            </li>
            <li>
              A description of the copyrighted work or protected work that you
              claim has been infringed;
            </li>
            <li>
              A description of where the material that you claim is infringing
              is located on the Services;
            </li>
            <li>Your address, telephone number, and e-mail address;</li>
            <li>
              A statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright or intellectual
              property owner, its agent, or the law; and
            </li>
            <li>
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright or intellectual property owner or authorized to act on
              the owner&apos;s behalf.
            </li>
          </ol>
        </p>
        <p>
          For notice of claims of copyright or other intellectual property
          infringement on the Services, please contact{" "}
          <a href="support@huddlefantasysports.com." target="_blank">
            support@huddlefantasysports.com.
          </a>{" "}
        </p>
        <p>
          It is Huddle Fantasy Sports’ policy, in appropriate circumstances, to
          terminate the accounts of users who are repeat infringers or who are
          repeatedly charged with infringement.
        </p>
        <h4> Disclaimer of Warranties </h4>
        <p>
          YOUR USE OF THE SERVICES IS ENTIRELY AT YOUR SOLE RISK. THE SERVICES
          ARE PROVIDED BY HUDDLE FANTASY SPORTS ON AN “AS IS” BASIS WITHOUT ANY
          REPRESENTATIONS OR WARRANTIES. HUDDLE FANTASY SPORTS EXPRESSLY
          DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. HUDDLE FANTASY SPORTS MAKES NO WARRANTY THAT (i) THE
          SERVICES WILL MEET YOUR REQUIREMENTS, (ii) THAT OPERATION OF THE
          SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR
          (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES
          WILL BE ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION, WHETHER ORAL
          OR WRITTEN, OBTAINED BY YOU FROM HUDDLE FANTASY SPORTS OR THROUGH THE
          SERVICES, WILL CREATE ANY WARRANTY REGARDING THE SERVICES NOT
          EXPRESSLY STATED IN THESE TERMS OF SERVICE.
        </p>
        <h4> Limitation of Liability </h4>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT HUDDLE FANTASY SPORTS WILL NOT
          BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
          OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS
          OF USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF HUDDLE FANTASY SPORTS
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM
          THE USE OR THE INABILITY TO USE THE SERVICES OR ANY OTHER MATTER
          RELATING TO THE SERVICES.
        </p>
        <p>
          In no event will Huddle Fantasy Sports be liable to you for any amount
          of money greater than the amount of fees that you have paid to Huddle
          Fantasy Sports, which you acknowledge to be a fair and reasonable sum
          in the event of any loss by you of any kind.
        </p>
        <p>
          You may have additional rights under certain laws (including consumer
          laws) that do not allow the exclusion of implied warranties, or the
          exclusion or limitation of certain damages. If these laws apply to
          you, the exclusions or limitations in this Agreement that directly
          conflict with such laws may not apply to you.
        </p>
        <h4> Indemnification </h4>
        <p>
          You agree to indemnify and hold Huddle Fantasy Sports and its
          affiliates, agents, employees, owners, directors, managers, and
          licensors harmless from any claim, demand, loss, costs, or expense,
          including attorneys’ fees, made by any person arising out of your
          violation of these Terms of Service, state or federal securities laws
          or regulations, your use of the Services, or any other person’s
          rights, including but not limited to infringement of any intellectual
          property or other proprietary or privacy right. Under no
          circumstances, including but not limited to a negligent act, will
          Huddle Fantasy Sports or its affiliates or agents be liable for any
          damages of any kind that result from the use of, or the inability to
          use the Services.
        </p>
        <h4> International Access; Import and Export </h4>
        <p>
          We understand that the Services may be accessible worldwide, including
          in locations outside the United States. However, we make no
          representation that the Services is appropriate or available for use
          in any location outside the U.S. We do not represent that the
          Services, these Terms of Service, and our Privacy Policy will comply
          with local laws, rules, and regulations in any locations outside the
          U.S. You understand and agree that your attempt to access, or your use
          of, the Services is solely at your own risk and that you are
          responsible for compliance with local laws, rules, and regulations in
          your jurisdiction. If the Services do not comply with your local laws,
          rules, and regulations, we ask that you stop using or attempting to
          access the Services.
        </p>
        <p>
          You further agree not to import or export products through the
          Services in violation of any applicable laws or regulations of the
          United States. You agree to indemnify, defend, and hold Huddle Fantasy
          Sports harmless from liability for any violation of any such laws or
          regulations.{" "}
        </p>
        <h4> Miscellaneous Provisions </h4>
        <p>
          <ol>
            <li>
              <b>Applicable Law and Jurisdiction </b> These Terms of Service and
              all future agreements you will enter into with Huddle Fantasy
              Sports unless otherwise indicated on such other agreement, will be
              governed by the laws of the United States of America and the State
              of Washington, without regard to conflicts of law principles
              thereof. This is the case regardless of whether you reside or
              transact business with Huddle Fantasy Sports or any of its
              affiliates or agents in the State of Washington or elsewhere.
              Unless a dispute would be governed by an applicable arbitration
              clause, you agree to submit to the personal and exclusive
              jurisdiction of the state and federal courts located within King
              County, Washington.
            </li>
            <li>
              <b>Severability </b> If any provision of these Terms of Service
              will be adjudged by any court of competent jurisdiction to be
              unenforceable or invalid, that provision will be limited or
              eliminated to the minimum extent necessary so that these Terms of
              Service will otherwise remain in full force and effect.
            </li>
            <li>
              <b>Waiver </b> The failure of Huddle Fantasy Sports to exercise or
              enforce any right or provision of these Terms of Service will not
              operate as a waiver of such right or provision. Any waiver of
              these Terms of Service by Huddle Fantasy Sports must be in writing
              and signed by an authorized representative of Huddle Fantasy
              Sports.
            </li>
            <li>
              <b>Relationship of the Parties </b> Nothing contained in these
              Terms of Service or your use of the Services will be construed to
              constitute either Party as a partner, joint venturer, employee or
              agent of the other party, nor will either Party hold itself out as
              such. Neither Party has any right or authority to incur, assume or
              create, in writing or otherwise, any warranty, liability or other
              obligation of any kind, express or implied, in the name of or on
              behalf of the other Party, it being intended by both Parties that
              each will remain independent and responsible for their own
              actions.
            </li>
            <li>
              <b>Entire Agreement </b>
              These Terms of Service constitute the entire agreement between you
              and Huddle Fantasy Sports and governs your use of the Services,
              and supersedes all prior or contemporaneous communications and
              proposals, whether electronic, oral or written, between you and
              Huddle Fantasy Sports with respect to the Services.
              Notwithstanding the foregoing, you may also be subject to
              additional terms and conditions, posted policies (including, but
              not limited to, the Privacy Policy), guidelines, or rules that may
              apply when you use the Services.
            </li>
          </ol>
        </p>
        <br />
        <h4>Contact Information </h4>
        <p>
          <a
            href="https://info@huddlefantasysports.com"
            target="_blank"
            rel="noreferrer"
          >
            support@huddlefantasysports.com
          </a>
        </p>
        <br />{" "}
      </div>
    </div>
  ) : (
    <div className="main-content">
      <div>
        <div className="d-flex flex-column justify-content-center card-header">
          <h3>
            PRIVACY &nbsp; <span>POLICY</span>
          </h3>

          <p>Effective as of January 16, 2024 </p>
        </div>
      </div>

      <div className="card-body">
        <h4>
          {/* Definitions */}
          General Purpose
        </h4>
        <p>
          Huddle Fantasy Sports (the “Company” or “we” or “us”) has developed
          this Privacy Policy to demonstrate its commitment to protecting the
          privacy of users of the website (the “Site”), the mobile application
          (the “App”), and the web-based fantasy league platform featured
          thereon (the “Platform”) (collectively, the “Services”). This Privacy
          Policy is intended to summarize and inform you (our “users” or “you”)
          of our general privacy and data protection practices regarding the
          collection, use, and disclosures of information when you use our
          Services, and the choices you have with respect to that information
        </p>
        <p>
          Please read this Privacy Policy carefully so that you can make an
          informed decision regarding whether to use our Services. When reading
          this Privacy Policy, please note that the term “including” (as well as
          related terms such as “include” and “includes”) means “including, but
          not limited to,” and is meant to be inclusive not exclusive.{" "}
        </p>
        <p>
          By accessing and continuing to use our Services, you are consenting to
          the practices described in this Privacy Policy. Your consent includes
          authorization for the Company to collect, receive, store, use, and
          disclose your information in accordance with this Privacy Policy and
          applicable law.{" "}
        </p>
        <h4>
          {/* Office ipsum you must be muted. */}
          Data Collected through User Submissions
        </h4>
        <p>
          We collect and store information that you voluntarily submit to us,
          including via registration or form block submission (collectively,
          “Voluntary information”). If you voluntarily identify yourself by
          sending us an email, we collect and store your email address and any
          other information you provide in your email. As described in more
          detail below, we may use this information for our operational and
          commercial purposes, including to contact you, identify you, and send
          you information, including marketing information, about our products
          and services.
          <br />
          While using our Services, we may ask you to provide us with certain
          information, which we may also use for our operational and commercial
          purposes above. Such information may include: <br />
        </p>
        <p>
          <ul>
            <li>Email address </li>
            <li>First and last name</li>
            <li>Phone number</li>
            <li>City and state</li>
            <li>Date of Birth</li>
            <li>Years of fantasy experience</li>
          </ul>
          You are not under any obligation to provide this information. However,
          if you withhold certain information, we may not be able to provide
          certain services to you.
        </p>
        <h4>
          Data Collected through User Visits, Interactions, and Activities
        </h4>
        <p>
          We use cookies, pixels, and other online tracking technologies to
          collect information, including to understand visitor activity on our
          Services and to help improve visitors’ experience while using our
          Services.
        </p>
        <p>
          Cookies are small text files that we use to help us collect data and
          to enhance your experience with our Services. For example, cookies
          allow us to recognize repeat visitors and provide expanded
          functionality. You consent to our use of both session cookies and
          persistent cookies. Session cookies expire once you close our Services
          and persistent cookies stay on your mobile device or computer until
          deleted by you. We may use cookies for various purposes, including,
          but not limited to analyzing which portions of our Services are
          visited and used most frequently and to personalize your experience
          with our Services. More information about the cookies that may be
          dropped on your device can be found by visiting{" "}
          <a href="https://allaboutcookies.org/." target="blank">
            https://allaboutcookies.org/.{" "}
          </a>
        </p>
        <p>
          If you do not want us to deploy cookies through our Services, you can
          opt out by setting your mobile device or computer to reject cookies.
          You can still use our Services if you choose to disable cookies,
          although your ability to use some of the features may be affected.
          Additional information that may be collected by cookies and other such
          tracking technology during your use of our Services may include:
        </p>
        <p>
          <ul>
            <li>
              Information about your browser type and language, network, and
              device{" "}
            </li>
            <li>Referring and exit web pages and URLs </li>
            <li>Your IP addresses (static and dynamic) </li>
          </ul>
        </p>
        <p>
          Information collected from your use of our Services includes
          (collectively, with information from cookies, the “Use Information”):
        </p>
        <p>
          <ul>
            <li>Clicks while using our Services</li>
            <li>Internal links visited </li>
            <li>Pages visited </li>
            <li>Scrolling</li>
            <li>Timestamps </li>
          </ul>
        </p>
        <p>
          We may share this Use Information with Google Analytics, a website
          analytics provider, to learn about site traffic and activity.{" "}
        </p>
        <h4>
          {/* Data Collected through Participation on the Platform  */}
          Data Collected through Participation on the Platform
        </h4>
        <p>
          Pursuant to facilitating public and private fantasy leagues on the
          Platform, we receive information about users who are active
          participants in managing teams (“Participants”). This information
          (“Participant Information”) is additional to information received
          about users who do not actively participate but merely observe public
          league activity or otherwise passively view the Services
          (“Observers”). Participant Information includes such data as name,
          email address, payment information, address, team name, team
          statistics (including individual game results, game statistics, and
          player statistics), team-level voting decisions and voting history
          (“Voting Insights”), team profile photos, Participant username,
          Participant profile photo, and any other information submitted or
          uploaded by the Participant onto the Platform via community post,
          comment, chat, or other content. Participant Information may be from
          Participants involved in public leagues (“Public Participant
          Information”) or from Participants involved in private leagues
          (“Private Participant Information”).{" "}
        </p>
        <br />
        <h4>
          {/* Links to Other Websites */}
          Purposes and Legal Basis for Collecting User Information
        </h4>
        <p>
          The table below lists our purposes for collecting user information as
          well as the legal justifications (“legal basis”) under data protection
          law that allows us to do so.{" "}
        </p>
        <p>
          <Table className="privacy-table">
            <thead border>
              <tr>
                <th>Purpose for processing your data </th>
                <th>Legal basis </th>
                <th>Categories of personal data used for that purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  To respond to user inquiries and communicate with users
                  regarding the Services{" "}
                </td>
                <td>
                  Legitimate interest in communicating with customers; Consent{" "}
                </td>
                <td>Voluntary Information, Participant Information </td>
              </tr>
              <tr>
                <td>
                  For marketing purposes such as sending promotional emails{" "}
                </td>
                <td>Legitimate interest in promoting the Services </td>
                <td>
                  Voluntary Information, Use Information, Participant
                  Information{" "}
                </td>
              </tr>
              <tr>
                <td>
                  To comply with a legal obligation that we are subject to,
                  including requests from law enforcement{" "}
                </td>
                <td>Compliance with legal obligations </td>
                <td>
                  Voluntary Information, Use Information, Participant
                  Information{" "}
                </td>
              </tr>
              <tr>
                <td>
                  For advertising purposes such as posting on social media to
                  generate engagement with public leagues{" "}
                </td>
                <td>Legitimate interest in promoting the Services </td>
                <td>Public Participant Information </td>
              </tr>
              <tr>
                <td>
                  For sharing with third party commercial partners in order to
                  generate capital for improving the Services{" "}
                </td>
                <td>
                  Legitimate interest in raising capital to improve the
                  Services.{" "}
                </td>
                <td>
                  Voluntary Information, Use Information, Participant
                  Information (excluding payment information){" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </p>
        <br />
        <h4>How Information is Used and Shared </h4>
        <p>
          You authorize us to collect, use, share, disclose and store the above
          Voluntary Information, Use Information, and Participant Information
          (collectively, the “Information”) as follows.
        </p>
        <p>
          <ol>
            <li>
              <b>Marketing and Promotion </b> &nbsp; We use Public Participant
              Information in our marketing activities in order to promote
              interest in the public leagues offered on the Platform.
            </li>
            <li>
              {" "}
              <b>Affiliated Third Parties </b> &nbsp; In order to provide and
              improve our services, we engage with certain third-party
              individuals and companies (collectively, “Third Party Service
              Providers”), such as Google Analytics, to perform functions on our
              behalf. You expressly authorize us to share the Information with
              our Third-Party Service Providers. We will provide our Third-Party
              Service Providers with access to the Information collected above
              only to the extent necessary to allow them to perform their
              functions and to the extent permitted by law. <br />
            </li>
            <li>
              <b>Commercial Partners </b> &nbsp; We reserve the right to share
              Information with our third party commercial partners in order to
              raise capital for the purpose of improving the Services.
              Notwithstanding the foregoing, we will only share Information that
              is sensitive in nature (e.g., information related to gender, race,
              account login, etc.) and Information gathered from users younger
              than 16 if you have provided us with opt-in permission to do so.
            </li>
            <li>
              <b>Data Analytics </b> &nbsp; In an ongoing effort to better
              understand our users and our Services, we may analyze certain
              Information in aggregate form to operate, maintain, manage, and
              improve the Services. This aggregate information does not identify
              you personally. We may share or license this aggregate data to our
              affiliates, agents, business and promotional partners, and other
              third parties to the extent necessary to improve our Services. We
              may also disclose aggregated user statistics to describe the
              Services to current and prospective business partners and
              investors. <br />
            </li>
            <li>
              <b>Internal Sharing </b> &nbsp; We may share some or all of your
              Information with any of our parent companies, subsidiaries, joint
              ventures, or other companies under our common control.
              <br />
            </li>
            <li>
              <b>Transmission of Information </b> &nbsp; We may transmit the
              Information to and from third party servers, which may include
              transmitting the Information across third party communication
              protocols and networks. You expressly consent to our transmission
              of Information across third party communication protocols and
              networks and agree to defend and hold the Company harmless from
              any and all claims relating to the same.
            </li>
            <li>
              <b>Storage of Information </b> &nbsp; We may store the Information
              on our servers or on servers owned by third parties, whether in
              the United States or elsewhere. We will use reasonable efforts to
              ensure that the Information is stored on secure servers with
              privacy practices that offer the same or equal protection of the
              Information as in this Privacy Policy.
            </li>
            <li>
              <b>Sale of Business or Transfer of Assets </b> &nbsp; We may sell
              or buy businesses or assets during the growth of our company. In
              the event of a corporate sale, merger, reorganization, sale of
              assets, dissolution, or similar event, the Information may be part
              of the transferred assets.
            </li>
            <li>
              <b>Disclosure Required by Law </b> &nbsp; We may disclose the
              Information when required by law, court order, or other government
              or law enforcement authority or regulatory agency. By agreeing to
              the terms of this Privacy Policy, you waive all rights and agree
              to hold the Company harmless from any claims resulting from any
              action taken by the Company during or as a result of law
              enforcement or regulatory investigations and from any actions
              taken at the direction of law enforcement or regulatory
              authorities.
            </li>
            <li>
              <b>Disclosure When Necessary Or Advisable </b> &nbsp; We may
              disclose the Information whenever we believe that disclosing such
              Information is necessary or advisable, for example, to protect the
              rights, property (including Intellectual Property), or safety of
              us or others, including you.
            </li>
            <li>
              <b>Troubleshooting and Dispute Resolution </b> &nbsp; We use the
              Information to resolve disputes, and troubleshoot problems.
            </li>
          </ol>
        </p>
        <h4> Disclosures You Make to Third Parties </h4>
        <p>
          The Company does not control the privacy policies of any unaffiliated
          third parties. When you disclose information to unaffiliated third
          parties, you are subject to the privacy policy of that third party. It
          is your responsibility to find out about the privacy policy of any
          such third parties. You agree to defend and hold the Company harmless
          from any and all claims regarding your disclosure of information to
          third parties unaffiliated with the Company.{" "}
        </p>
        <h4>Emails </h4>
        <p>
          You may receive marketing or promotional communications from us upon
          submitting Voluntary Information. You can opt out of these
          communications by clicking on the “unsubscribe” link located on the
          bottom of any of our e-mails. We will use commercially reasonable
          efforts to process such requests in a timely manner. You should be
          aware, however, that it is not always possible to completely remove or
          modify information in our databases. You cannot opt out of receiving
          transactional e-mails or messages related to the Services, such as
          requests for support and other like communications, and as described
          in more detail below. <br />
        </p>
        <p>
          We may share certain Information, such as your email address our email
          marketing provider so that they can send certain emails on our behalf.{" "}
        </p>
        <p>
          <b>Marketing Emails </b>
          <br />
          We may send you marketing emails, which you can unsubscribe from by
          clicking the link at the bottom of the email.
          <br />
          <b>Account Emails </b>
          If you are a participant in a league and have an account for the
          Services, we may email you with messages about your access to the
          password-protected features of the Services. For example, we may email
          you with information regarding login information. It is not possible
          to unsubscribe from these messages as they are critical to our
          provision of services to you.{" "}
        </p>
        <h4>Do Not Track </h4>
        <p>
          We do not monitor, recognize, or respond to any opt-out or do not
          track mechanisms, including general web browser “Do Not Track”
          settings or signals. Further, we do not authorize third parties to
          collect any personally identifiable information about individuals who
          visit our Site without separate and express user consent.{" "}
        </p>
        <h4>Fonts</h4>
        <p>
          This Services may use font files from third party plugins. To properly
          display the Services to you, servers where the font files are stored
          may receive personal information about you, including:{" "}
        </p>
        <p>
          <ul>
            <li>Information about your browser, network, or device </li>
            <li>Your IP addresses </li>
          </ul>
        </p>
        <h4>How We Protect Your Information </h4>
        <p>
          We take commercially reasonable steps to protect the Information from
          loss, misuse, and unauthorized access, disclosure, alteration, or
          destruction. Please understand, however, that no security system is
          impenetrable. We cannot guarantee the security of our databases or the
          databases of the third parties with which we may share such
          Information, nor can we guarantee that the Information you supply will
          not be intercepted while being transmitted over the Internet or other
          communication protocols. In particular, e-mail sent to us may not be
          secure, and you should therefore take special care in deciding what
          information you send to us via e-mail. We retain all Information
          indefinitely but will delete a user’s Information upon request by that
          user.
        </p>
        <h4> Important Notices to Non-U.S. Residents </h4>
        <p>
          The Services are operated in the United States. If you are located
          outside of the United States, please be aware that your Information,
          including your Personal Information, may be transferred to, processed,
          maintained, and used on computers, servers, and systems located
          outside of your state, province, country, or other governmental
          jurisdiction where the privacy laws may not be as protective as those
          in your jurisdiction. If you are located outside the United States and
          choose to use the Services, you hereby irrevocably and unconditionally
          consent to such transfer, processing, and use in the United States and
          elsewhere.{" "}
        </p>
        <h4>Children </h4>
        <p>
          IF YOU ARE 13 YEARS OR YOUNGER, PLEASE DO NOT USE THE COMPANY WEBSITE
          OR ANY OF ITS SERVICES FOR ANY PURPOSE AT ANY TIME.
        </p>
        <p>
          The Services are intended for use by adults only. We adhere to the
          Children’s Online Privacy Protection Act (COPPA) and will not
          knowingly collect Personal Information from any child under the age of
          13. If a child under the age of 13 has provided us with Personal
          Information, a parent or guardian of that child may contact us and
          request that such information be deleted from our records. Users who
          provide information about their children have the same rights with
          respect to their children’s information as they do with respect to all
          other Information provided, including the rights to erase, rectify,
          and access.{" "}
        </p>
        <h4> Changes to This Privacy Policy </h4>
        <p>
          This Privacy Policy is effective as of the date stated at the top of
          this Privacy Policy. We reserve the right to change this policy from
          time to time and in its sole discretion. Any such changes will be
          posted to the Services. We may also send you notices by email or
          through the Services after we make any such changes to this Privacy
          Policy. Each time you use the Services, the current version of the
          Privacy Policy will apply. By accessing the Services after we make any
          changes to this Privacy Policy, you are deemed to have accepted such
          changes. Please be aware that, to the extent permitted by applicable
          law, our use of the Information is governed by the Privacy Policy in
          effect at the time we collect the Information. Accordingly, when you
          use our Services, you should check the “Effective Date” at the top of
          the Privacy Policy and review any changes or updates since your last
          visit.
        </p>
        <h4>Governing Law </h4>
        <p>
          This Privacy Policy will be governed by the laws of the United States
          of America and the state of Washington without regard to its conflict
          of laws principles. Users agree that the state and federal courts in
          King County, Washington will have personal jurisdiction over them in
          connection with any such disputes.
        </p>
        <h4>Indemnification </h4>
        <p>
          You agree to indemnify and hold Huddle Fantasy Sports and its
          affiliates, agents, employees, owners, directors, managers, and
          licensors harmless from any claim, demand, loss, costs, or expense,
          including attorneys’ fees, made by any person arising out of your
          violation of these Terms of Service, state or federal securities laws
          or regulations, your use of the Services, or any other person’s
          rights, including but not limited to infringement of any intellectual
          property or other proprietary or privacy right. Under no
          circumstances, including but not limited to a negligent act, will
          Huddle Fantasy Sports or its affiliates or agents be liable for any
          damages of any kind that result from the use of, or the inability to
          use the Services.
        </p>
        <h4> Your Data Protection Rights </h4>
        <p>
          We would like to make sure you are fully aware of all your data
          protection rights. Depending on where you live, you may have some or
          all of the following rights:
        </p>
        <p>
          <ul>
            <li>
              <b>Right to Know:</b> The right to request that we disclose to you
              the Information about you that we collect, use, or share, and
              information about our data practices.{" "}
            </li>
            <li>
              <b>Right to Request Correction: </b> The right to request that we
              correct inaccurate Information that we collect about you.{" "}
            </li>
            <li>
              <b>Right to Request Deletion:</b> The right to request that we
              delete your Information.{" "}
            </li>
            <li>
              <b>Right to Opt Out of Targeted Advertising:</b> The right to opt
              out of the processing of your Information obtained from your
              activities on nonaffiliated websites or online applications for
              the purposes of targeted advertising.{" "}
            </li>
            <li>
              <b>Right to Non-Discrimination:</b> The right not to receive
              discriminatory treatment for exercising your privacy rights.{" "}
            </li>
          </ul>
        </p>
        <p>
          If you would like to exercise any of these rights, please contact us.{" "}
        </p>
        <h4>How to Contact Us </h4>
        <p>
          If you have any questions about this Privacy Policy, please e-mail us
          at:&nbsp;
          <b>
            <a
              href="support@huddlefantasysports.com"
              target="_blank"
              rel="noreferrer"
            >
              support@huddlefantasysports.com
            </a>
          </b>
        </p>
        <br />{" "}
      </div>
    </div>
  );
};

AgreementContent.PropTypes = {
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
};

export default AgreementContent;
