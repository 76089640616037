import Home from "./Modules/Home/HomePage";
import AgreementPage from "./common/helper/footer/AgreementPage";

export const Route = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/agreement",
    element: <AgreementPage />,
  }
];
