import { React } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import Buttons from "../../common/Button/Buttons";
import { allimages } from "../../common/helper/AllImg/Allimages";
import "./style.css";

function Banner() {
  return (
    <Container className="mainPage floating">
      <Row>
        <Col className="ls-banner" xs={12} md={6}>
          <div className="bannerContent ">
            <Fade top>
              <h4>
                Manage Your <br />
                Fantasy Team With
                <br />
                <span className="sqdName"> Your Squad</span>
              </h4>
            </Fade>

            <p className="main-des">
              Experience the power of <b>group-based management</b> in fantasy sports.
              Huddle, debate, & compete against other squads within seasonal
              fantasy leagues!
            </p>
            <br />

            {/* Assuming Buttons and openmodel are defined */}
            <Buttons text="Download the App" styles="dwnbtn" />
          </div>
        </Col>
        <Col className="rs-banner" xs={12} md={6}>
          <Fade right>
            <img src={allimages.bannerPhone} alt="Banner Phone" />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
}

export default Banner;
