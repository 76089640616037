import Navbar from "../../common/helper/navbar/Navbar";
import Banner from "./Banner.js";
import Footer from "../../common/helper/footer/Footer.js";
// import Heropage from "./Heropage.js";
import "./style.css"
import RoleBasedManger from "./RoleBasedManger.js";
import DownloadSection from "./DownloadSection.js";
import PublicandFantacies from "./PublicandFantacies.jsx";


const HomePage = () => {
  return (
    <>
      <span className="banner">
        <Navbar />
        <section>
          <Banner />
        </section>      </span>
      {/* <Heropage /> */}
      <RoleBasedManger />
      <PublicandFantacies/>
      <DownloadSection />
      <Footer />
    </>
  );
};
export default HomePage;
