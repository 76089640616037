
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { allimages } from "../AllImg/Allimages";
import "./style.css";
import { useNavigate } from 'react-router-dom';



function NavScrollExample() {
  const navItems = [allimages.GoogleStoreImg, allimages.AppleStoreImg];
  const navigate=useNavigate()
  return (
    <div className='NavbarMain'>
      <Container className='' >
        <Navbar expand="sm" className="fixed" >
          <Navbar.Brand  onClick={()=>navigate('/')} style={{cursor:"pointer"}}>
            <img src={allimages.Logo}  />

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 "
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav
                className="me-auto my-2 my-lg-0">

              </Nav>
            </Nav>
            <Nav
              className=" d-flex my-2 my-lg-0"

            >
              <Nav
                className="d-flex mr-auto my-2 my-lg-0 gap-3"
                navbarScroll
              >
                {navItems.map((item) => (
                  <span key={item}>
                    <Nav.Item >
                      <img src={item} alt={item} />
                    </Nav.Item>
                  </span>
                ))}
              </Nav>
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >

              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>

  );
}

export default NavScrollExample;
