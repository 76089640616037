import React from "react";
import "./style.css";

// eslint-disable-next-line react/prop-types
const Buttons = ({ text, styles, clickHandler, img }) => {
  return (img ?
    <span className={`${styles}`}>
      <img src={img} />
      <button className="inheritCLasss" onClick={clickHandler}>{text}</button>
    </span>
    : <button className={`${styles}`} onClick={clickHandler}>{text}</button>);
};

export default Buttons;

