import { Row, Col } from "react-bootstrap";
import { allimages } from "../AllImg/Allimages";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { openmodel } from "./utils";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer-main text-light img-fluid">
      <Row>
        <Col xs={12} md={3} className="mb-3 mb-md-0">
          <div className="d-flex gap-2">
            <img src={allimages.twitter} alt="twitter" />
            <img src={allimages.facebook} alt="facebook" />
            <img src={allimages.insta} alt="instagram" />
          </div>
        </Col>
        <Col xs={12} md={5} className="d-flex gap-2 align-items-center justify-content-center ">
          <h5>Copyright © 2023 Huddle_Fantasy_Sports</h5>
        </Col>
        <Col
          xs={12}
          md={4}
          className="d-flex gap-2 align-items-center justify-content-center agreement-section"
        >
          <h5
            onClick={() => {
              openmodel(navigate, "T&C");
            }}
          >
            Terms & Conditions
          </h5>
          <span className="dot"></span>
          <h5
            className="privacy"
            onClick={() => {
              openmodel(navigate, "privacy");
            }}
          >
            Privacy Policy
          </h5>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
