import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { allimages } from "../../common/helper/AllImg/Allimages";
import "./style.css";
import { Fade } from "react-reveal";

const PublicandFantacies = () => {
  return (
    <>
      <Container className="mainPage  d-flex justify-content-center mt-5">
        <Row>
          <Col className=" d-flex flex-column justify-content-center">
            <Fade left>
              <h2 className="mb-3 coursel-header animate__animated animate__flipInX ">
                Public Fantasy Leagues?
              </h2>
              {/* Role Based Management Section */}
              <div className="d-flex justify-between items-stretch flex flex-wrap gap-5 coursel-header-content">
                <div className="flex flex-col ">
                  <div
                    className=" mt-3 w-100 mb-4"
                    style={{ fontSize: "20px" }}
                  >
                    <h2 className="mb-5 coursel-header animate__animated animate__flipInX ">
                      Insights from Collaboration
                    </h2>

                    <p className="mt-3" style={{fontWeight:400,    color: "#a2a3a6"}}>
                      Yes! Anyone can follow other leagues to watch the
                      competition throughout the season.
                    </p>
                    <br />
                    <p style={{    color: "#a2a3a6"}}>
                      Be sure to follow our social channels to stay up to date
                      on{" "}
                      <span className="sqdName-mini">
                        highlights & entertaining
                      </span>{" "}<br/>
                      content generated from public leagues.
                    </p>
                    <div className="d-flex gap-5 mt-5 fantacy-logo">
                      <img src={allimages.twitter} alt="twitter" />
                      <img src={allimages.facebook} alt="facebook" />
                      <img src={allimages.insta} alt="instagram" />
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
          <Col
            xs
            lg="3"
            md="6"
            className="d-flex download-image  justify-content-center"
          >
            <Fade right>
              <img
                src={allimages.RoleBaseManagerBannner}
                alt="squad"
                className="  align-bottom "
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PublicandFantacies;
