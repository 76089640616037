import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common/responsive.css";
import { useRoutes } from "react-router-dom";
import { Route } from "./Route";
import "slick-carousel/slick/slick.css";
import AnimatedCursor from "react-animated-cursor";

import "slick-carousel/slick/slick-theme.css";

function App() {
  const allRoutes = useRoutes(Route);
  return (
    <>
      {allRoutes}
      <AnimatedCursor
        innerSize={12}
        outerSize={35}
        innerScale={1}
        outerScale={1.7}
        outerAlpha={0}
        outerStyle={{
          border: "3px solid #8261c2",
        }}
        innerStyle={{
          background: "linear-gradient(90deg, #8261C2 0%, #35B795 100%)",
        }}
      />
    </>
  );
}

export default App;
