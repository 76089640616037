import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { allimages } from "../../common/helper/AllImg/Allimages";
import Buttons from "../../common/Button/Buttons";
import { downloadApp } from "./utils";
import "./style.css";
import { Fade } from "react-reveal";

const DownloadSection = () => {
  return (
    <>
      <Container className=" floating justify-content-center " style={{paddingBottom:"3rem"}}>
        <Row>
          <Col
            xs
            lg="3"
            md="6"
            className="d-flex download-image  justify-content-center"
          >
            <img
              src={allimages.squadImage}
              alt="squad"
              className="  align-bottom "
            />
          </Col>
          <Col className="squad-mainDiv">
            <Fade right>
              <div>
                <h4>
                  Ready <span className="squadHighlight">to Elevate</span> Your{" "}
                  <br />
                  Fantasy Sports?
                </h4>
                <p style={{fontSize:"16px",fontFamily:400,    color: "#a2a3a6"}}>
                  Season already started... No worries! Join the waitlist below <br/>
                  for next seson
                </p>
              </div>
              <Fade top>
                <div className="d-flex gap-3 flex-wrap">
                  <Buttons
                    text={`Join Waitlist `}
                    styles={`dwnldbtnb`}
                    clickHandler={downloadApp}
                  />
                </div>
              </Fade>
            </Fade>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DownloadSection;
