import bannerPhone from "../../../Assets/Images/bannerphoneright.png";
import Logo from "../../../Assets/Images/Logo.png";
import GoogleStoreImg from "../../../Assets/Images/googlePLay.svg";
import AppleStoreImg from "../../../Assets/Images/AppleStore.svg";
import insta from "../../../Assets/Images/insta.svg";
import facebook from "../../../Assets/Images/facebook.svg";
import twitter from "../../../Assets/Images/twitter.svg";
import logovedio from "../../../Assets/vedios/logovedio.gif";
import herocelebrate from "../../../Assets/Images/herocelebrate.svg";
import herogame from "../../../Assets/Images/herogame.svg";
import heropeople from "../../../Assets/Images/heropeople.svg";
import duelMobile from "../../../Assets/Images/duelMobile.png";
import heroacordation from "../../../Assets/Images/heroaccordation.png";
import squadImage from "../../../Assets/Images/squadImage.png";
import squadBg from "../../../Assets/Images/squadBg.svg";
import { ReactComponent as processicon1 } from "../../../Assets/Images/processicon1.svg";
import { ReactComponent as processicon2 } from "../../../Assets/Images/processicon2.svg";
import { ReactComponent as coureselpadimg } from "../../../Assets/Images/coureselpadimg.svg";
import { ReactComponent as courselGroupImg } from "../../../Assets/Images/courselGroupImg.svg";
import { ReactComponent as courselchat } from "../../../Assets/Images/courselchat.svg";
import { ReactComponent as handShakesvg } from "../../../Assets/Images/handShake.svg";
import { ReactComponent as helmet } from "../../../Assets/Images/helmet.svg";
import { ReactComponent as scrumTeam } from "../../../Assets/Images/scrumTeam.svg";
import { ReactComponent as AssisitCouchHover } from "../../../Assets/Images/Assistant Coach Hover.svg";
import RoleBaseManagerBannner from "../../../Assets/Images/RoleBaseManagerBannner.png";
import heroChatIcon from "../../../Assets/Images/heroChatIcon.png";
import heroPageRingIcon from "../../../Assets/Images/heroPageRingIcon.svg";
import googleplayicon from "../../../Assets/Images/googleplayicon.svg";
import appleicon from "../../../Assets/Images/appleicon.svg";
import grphover from "../../../Assets/Images/grp-hover.svg";
import dot from "../../../Assets/Images/dot.svg";
import centerImgrole from "../../../Assets/Images/centerImgrole.svg";

export const allimages = {
  bannerPhone,
  Logo,
  GoogleStoreImg,
  AppleStoreImg,
  insta,
  facebook,
  twitter,
  logovedio,
  herocelebrate,
  herogame,
  heropeople,
  duelMobile,
  heroacordation,
  squadImage,
  squadBg,
  processicon1,
  processicon2,
  coureselpadimg,
  courselGroupImg,
  courselchat,
  RoleBaseManagerBannner,
  heroPageRingIcon,
  heroChatIcon,
  googleplayicon,
  appleicon,
  grphover,
  handShakesvg,
  scrumTeam,
  helmet,
  dot,
  AssisitCouchHover,
  centerImgrole,
};
